import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './footer.scss';

function Footer(){
    return(
        <section className='FooterSec'>
            <Container>
               <div className="threeColumn">
                <div>
                    <img src="/images/logoWhite.svg" alt='Akhil Bharatiya Prathibha Utthan Abhiyan' />
                    <span>
                        <Link to={''}><img src="/images/linkedin.svg" alt="linkedin" /></Link>
                        <Link to={''}><img src="/images/facebook.svg" alt="facebook" /></Link>
                        <Link to={''}><img src="/images/instagram.svg" alt="instagram" /></Link>
                    </span>
                </div>
                <div className='letsTalk'>
                    <b>Let's Talk</b>
                    <a href="tel:+917217888821"><img src="/images/phonecall.svg" alt="phone" /> +91- 72178 88821</a>
                    <a href="mailto:contact@abpua.org"><img src="/images/mail.svg" alt="mail" /> contact@abpua.org</a>
                </div>
                <div className='headOffice'>
                    <b>Headoffice</b>
                    <p>Akhil Bharatiya Prathibha Utthan Abhiyan E-201, Sujjan Vihar, Sector 43, Gurugram, Haryana</p>
                </div>
               </div>
               <div className="copyRight">
               &copy; 2023 Akhil Bharatiya Prathibha Utthan Abhiyan. All Rights Reserved
               </div>
            </Container>
        </section>
    )
}
export default Footer;